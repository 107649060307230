export const state = () => ({
    errorCodes: [],
    errorStrings: {},
})

export const actions = {
    async getErrorCodes({ commit }, data) {
        const params = { ...data }

        // delete params.sort // It's a default params from dataTable mixins
        if (!params.search_text) delete params.search_type

        const numberOnlyRegex = /^[0-9]+$/

        if (
            params.search_type === 1 &&
            !numberOnlyRegex.test(params.search_text)
        )
            return

        await this.$axios
            .get('/website-setting/error-strings', { params })
            .then((data) => {
                commit('setErrorCodes', data.data)
            })
            .catch(() => {})
    },
    async updateErrorCode({ commit }, data) {
        const response = await this.$axios
            .put(`/website-setting/error-strings/${data.code}`, {
                text: data.text,
            })
            .then((data) => {
                return true
            })
            .catch(() => {
                return false
            })
        return response
    },
    async getErrorStrings({ commit }, langCode = 'en-US') {
        await this.$axios
            .get('/white-label/error-strings', {
                params: {
                    lang_code: langCode,
                },
            })
            .then((data) => {
                commit('setErrorStrings', { data: data.data, lang: langCode })
            })
            .catch(() => {})
    },
}

export const mutations = {
    setErrorCodes: (state, data) => {
        state.errorCodes = data
    },

    setErrorStrings: (state, data) => {
        const { data: errorStrings, lang } = data

        // Change array of objects to object for easier access,
        // Flatten the text object to make it easier to access
        const flattenedObject = errorStrings.reduce((acc, item) => {
            acc[item.code] = item.text[lang]
            return acc
        }, {})

        state.errorStrings = flattenedObject
    },
}
